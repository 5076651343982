// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { Message } from "../../../framework/src/Message";
import {
    getStorageData,
} from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

interface File {
    title: string;
    count: number;
    file: SubFile[];
}

interface SubFile {
    fileName: string;
    size: string;
}

interface S {
    expandedIndex: number | null,
    menuAnchor: HTMLElement | null;
    activeFile: SubFile | null;
    currentStatus: string;
    menuItemIndex: number | null;
    activeTab: number,
    sortTypeValue: string;
    sortType: { label: string; value: string }[];
    searchText: string,
    loading: boolean;
    projects: ({
        start_date: string | number | Date;
        startDate: string | number | Date;
        id: string;
        title: string;
        location: string;
        draft: boolean;
        status: string;
        cost: string;
        files_uploaded: boolean;
    } | PortfolioProjects)[];
    openDialogLeave: boolean;
    handleDuplicateProject: (() => void) | null;
    openDialog: boolean;
    menuAnchorSort: HTMLElement | null,
    sortTypeText: string,
    token: string;
}
interface PortfolioProjects {
    id: string;
    start_date: string;
    title: string;
    location: string;
    draft: boolean;
    status: string;
    cost: string;
    files_uploaded: boolean;
    description?: string;
}
interface SS {
    id: any;
}

export default class SpecialistCostPlanProjectsController extends BlockComponent<
    Props,
    S,
    SS
> {
    fileInputRef = React.createRef<HTMLInputElement>();
    apiDuplicateProjectByIdCallId: string = ''
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            expandedIndex: null,
            menuAnchor: null,
            activeFile: null,
            currentStatus: 'Pending Approval',
            menuItemIndex: null,
            activeTab: 0,
            sortTypeValue: "date_asc",
            sortTypeText: 'Newest First',
            sortType: [
                { label: "Newest First", value: "date_asc" },
                { label: "Oldest First", value: "date_desc" },
                { label: "A to Z (Project Name)", value: "alpha_asc" },
                { label: "Z to A (Project Name)", value: "alpha_desc" },
            ],
            searchText: '',
            loading: false,
            projects: [],
            openDialogLeave: false,
            handleDuplicateProject: null,
            openDialog: false,
            menuAnchorSort: null,
            token: ''
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }
   
    async componentDidMount() {
        const token = await getStorageData("token");
        this.setState({ token });
    }

    toggleExpand = (index: number) => {
        this.setState((prevState) => ({
            expandedIndex: prevState.expandedIndex === index ? null : index,
        }));
    };
    handleMenuOpen = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
        this.setState({
            menuAnchor: event.currentTarget,
        });
    };
    openSortDropdown = (event: React.MouseEvent<HTMLDivElement>) => {
        this.setState({
            menuAnchorSort: event.currentTarget,
        })
    }
    handleMenuClose = () => {
        this.setState({ menuAnchorSort: null });
    };

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ activeTab: newValue });
    };
    handleSortByChange = (event: string, option: string) => {
        this.setState({ sortTypeValue: event, sortTypeText: option }, () => { });
    };
    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchText: event.target.value });
    };
    getFilteredAndSortedProjects = () => {
        const { projects, activeTab, sortTypeValue, searchText } = this.state;

        const filteredBySearch = projects.filter(
            (project) =>
                project.title &&
                project.title.toLowerCase().includes(searchText.toLowerCase())
        );

        const filteredByTab = this.filterProjectsByStatus(filteredBySearch, activeTab);
        return this.sortProjects(filteredByTab, sortTypeValue);
    };
    filterProjectsByStatus = (projects: any[], activeTab: number | null) => {
        const statusMapping: { [key: number]: string } = {
            0: 'Pending',
            1: 'Approved',
            2: 'Rejected',
            3: 'Draft',
        };

        return activeTab && statusMapping[activeTab]
            ? projects.filter((project) => project.status === statusMapping[activeTab])
            : projects;
    };

    sortProjects = (projects: any, sortType: any) => {
        return projects.sort((a: any, b: any) => {
            if (sortType === 'date_asc') {
                return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
            } else if (sortType === 'date_desc') {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            } else if (sortType === 'cost') {
                return a.cost - b.cost;
            }
            return 0;
        });
    };
    openDuplicateDialog = (project_id: any) => {
        this.setState({
            openDialogLeave: true,
            handleDuplicateProject: () => this.handleDuplicateProject(project_id)
        });

    }
    handleDuplicateProject = async (project_id: any) => {
        this.handleCloseDialog();
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: await getStorageData("token"),
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiDuplicateProjectByIdCallId = requestMessage.messageId;

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.duplicateProjectByIdAPIEndPoint}/${project_id}/duplicate`
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.duplicateAPiEndMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    handleCloseDialog = () => {
        this.setState({ openDialog: false, openDialogLeave: false, menuAnchor: null })

    }
    handleCreateNew = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "CreateEditProjectPortfolio"
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), 3);
        this.send(message);
    }
    projectClick = (projectId: number) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "SpecialistCostPlan"
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), projectId);
        this.send(message);
    }
}

// Customizable Area End
