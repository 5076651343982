// Customizable Area Start
import React from "react";

import {
    Box,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    TextField,
    Divider,
    Grid,
    Dialog,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { backArrowIcon, cursorIcon, electricicon, handIcon, toolfive, toolfour, toolone, toolseven, toolsix, toolthree, tooltwo } from "./assets";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
const CustomSearchBox = styled((TextField))({
    width: '100%',
    "& .MuiOutlinedInput-root": {
        borderRadius: '8px',
        height: '44px',
        "& fieldset": {
            borderColor: '#CBD5E1',
        },
        "&:hover fieldset": {
            borderColor: '#CBD5E1',
        },
        "&.Mui-focused fieldset": {
            borderColor: '#CBD5E1',
        },
    },
    "& .MuiOutlinedInput-input": {
        padding: "10px 0px",
        fontWeight: 400,
        fontSize: '16px',
        fontFamily: 'Poppins',
        "&::placeholder": {
            color: '#000',
        }
    },
    "& .MuiInputLabel-outlined": {
        transform: "translate(10px, 10px)"
    },
    "& .MuiFormLabel-root": {
        fontSize: '15px',
        color: '#000'
    }
});
import MarkupToolController, {
    Props,
} from "./MarkupToolController";
import { Clear, KeyboardArrowUp, Search } from "@material-ui/icons";

export default class MarkupTool extends MarkupToolController {

    constructor(props: Props) {
        super(props);
    }

    render() {
        const { currentPage, totalPages, zoom } = this.state;
        const { tool} = this.state;
        const boxStyle = (currentTool: string) => ({
            backgroundColor: tool === currentTool ? '#E0EDF0' : '#ffffff',
            padding: '10px',
            borderRadius: '8px',
            cursor: 'pointer',
            display: 'inline-block',
        });

        const tools = [
            { image: toolone, toolname: 'name' },
            { image: tooltwo, toolname: 'name' },
            { image: toolfive, toolname: 'name' },
            { image: toolseven, toolname: 'name' },
            { image: toolthree, toolname: 'name' },
            { image: toolsix, toolname: 'name' },
            { image: toolfour, toolname: 'name' },
            { image: toolfour, toolname: 'name' },
        ]
        return (
            <ThemeProvider theme={theme}>
                <MainWrapper>
                    <MyProfileHeader navigation={this.props.navigation} tabName={'Scaling&Drawings'} />
                    <CustomUploadDialog PaperProps={{
                        style: {
                            width: '584px',
                            borderRadius: "16px",
                            padding: "24px 24px",
                            color: "#FFFFFF",
                            boxSizing: "border-box",

                        }
                    }} open={this.state.openDialog} data-test-id="handleCLoseDialog" onClose={this.handleCLoseDialog}>
                        <Box>
                            <Box
                                sx={{
                                    justifyContent: "space-between",
                                    marginBottom: "40px",
                                    position:'relative'
                                }}
                            >
                                <Typography className="dialogTitleTypo">Unsaved Changes Detected</Typography>
                                <Typography className="subTitle">Are you sure you want to return to the projects page?  Unsaved changes on the canvas will be lost.</Typography>
                                <Typography
                                    data-test-id="handleCloseUploadDialog"
                                    onClick={this.handleCLoseDialog}
                                    style={{
                                        color: "black",
                                        width: "20px",
                                        position:'absolute',
                                        top:3,right:4,cursor:'pointer'
                                    }}
                                >
                                    <Clear />
                                </Typography>
                            </Box>
                            <Box style={{display:'flex',justifyContent:'flex-end'}}>
                                <CloseButton onClick={this.handleGoBack} style={{background:'#E0EDF0',color:'#325962',marginRight:'16px'}}>Discard and Return</CloseButton>
                                <CloseButton onClick={this.handleGoBack}>Save and Return</CloseButton>
                            </Box>
                           
                        </Box>
                    </CustomUploadDialog>
                    <Box sx={{ display: 'flex', bgcolor: '#FFFFFF', border: '1px solid #E2E8F0', justifyContent: 'space-between', padding: '8px 24px 8px 16px' }}>
                        <Box style={{ display: 'flex', gap: '26px', alignItems: 'center' }}>
                            <Box data-test-id="handleGoBack" onClick={this.handleClose}>
                                <img src={backArrowIcon} alt='arrow' />
                            </Box>
                            <Box>
                                <Typography style={{
                                    color: '#0F172A',
                                    fontFamily: 'Poppins',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '24px',
                                    textAlign: 'left',
                                    textUnderlinePosition: 'from-font',
                                    textDecorationSkipInk: 'none',
                                }}>{this.state.fileName}</Typography>
                                <Box style={{ display: 'flex' }}>
                                    <img src={electricicon} />
                                    <Typography style={{ color: '#64748B', fontSize: '12px', fontWeight: 400, textAlign: 'center', marginLeft: '8px' }}>{this.state.toolTypeSelected}</Typography>
                                </Box>
                            </Box>

                        </Box>
                        <Box style={{ display: 'flex', gap: '16px', alignItems: 'center', marginLeft: '-135px' }}>
                            <Box data-test-id='selectTool' onClick={() => this.selectTool('arrow')} style={boxStyle('arrow')}>
                                <img src={cursorIcon} alt='arrow' />
                            </Box>
                            <Box data-test-id='selectTool2' onClick={() => this.selectTool('hand')} style={boxStyle('hand')}>
                                <img src={handIcon} alt='arrow' />
                            </Box>

                        </Box>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Button style={{ color: '#237182', fontWeight: 600, fontSize: '16px', textTransform: 'none',marginRight:'16px' }}
                             data-test-id="handleClose" onClick={this.handleClose}>
                                Cancel
                            </Button>
                            <CloseButton data-test-id='handelSave' onClick={this.handleSave}>
                                Save
                            </CloseButton>

                        </Box>
                    </Box>
                    <Grid container>
                        <Grid item xs={12} sm={10}>
                            <Box style={{ background: '#F0F5F7', padding: '24px 32px 82px 32px' }}>

                                <Box sx={{ display: 'flex', width: '100%', bgcolor: '#FFFFFF', justifyContent: 'center' }}>
                                    <div>
                                        <div className="pdf-viewer">
                                            <div>
                                                <div className="canvas-container" data-test-id="mouseEvent" onMouseDown={this.onMouseDown}
                                                    onMouseMove={this.onMouseMove}
                                                    onMouseUp={this.onMouseUp}>
                                                    <canvas ref={this.canvasRef}></canvas>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </Box>
                                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="pdf-controls">

                                        <Typography className="toolbar">
                                            <span className="activePage"> {currentPage}</span> / {totalPages}
                                        </Typography>
                                        <Box style={{ width: '1px', background: '#E2E8F0', height: '35px', borderRadius: '1px', marginLeft: '16px',marginRight:'16px' }} />

                                        <button data-test-id="handleZoomOut" className="buttons" style={{ marginRight: '5px' }} onClick={this.handleZoomOut}>-</button>
                                        <p className="activePage">{(zoom * 100).toFixed(0)}%</p>
                                        <button data-test-id="handleZoomIn" className="buttons" onClick={this.handleZoomIn}>+</button>
                                    </div>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Box style={{ background: '#fff', padding: '8px' }}>
                                <Typography style={{ color: '#1E293B', fontSize: '14px', fontWeight: 600, marginTop: '8px' }}>
                                    Resources pannel
                                </Typography>
                                <Divider style={{ marginBottom: '16px', marginTop: '16px' }} />
                                <Typography style={{ color: '#1E293B', fontSize: '12px', fontWeight: 600 }}>Using the Markup Tool</Typography>
                                <Typography style={{ color: '#475569', fontSize: '12px', fontWeight: 400, marginTop: '4px', marginBottom: '16px' }}>
                                    Click on the Markup Tool and Place it on the PDF Canvas</Typography>
                                <CustomSearchBox
                                    data-test-id="handleSearchChange"
                                    variant="outlined"
                                    placeholder="Search"
                                    value={this.state.searchText}
                                    onChange={(e: any) => this.handleSearchChange(e)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search style={{ color: '#94A3B8' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Box style={{ display: 'flex', border: '1px solid #CBD5E1', padding: '10px 8px', borderRadius: '8px', marginTop: '16px', justifyContent: 'space-between' }}>
                                    <img src={electricicon} />
                                    <Typography style={{ color: '#0F172A', fontSize: '16px', fontWeight: 400, }}>{this.state.toolTypeSelected}</Typography>
                                    <KeyboardArrowUp style={{ color: '#94A3B8' }} />
                                </Box>
                                <Grid container style={{ marginTop: '16px', textAlign: 'center' }}>
                                    {tools.map((item) => (
                                        <Grid item sm={6} style={{ textAlign: 'center', marginBottom: '12px' }} data-test-id="handleToolClick"
                                        onClick={() => this.handleToolClick(item.image)}>
                                            <img src={item.image} style={{ width: '30px', height: '30px' }} />
                                            <Typography style={{ color: '#64748B', fontSize: '12px', fontWeight: 400 }}>{item.toolname}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Grid>

                    </Grid>

                </MainWrapper>
            </ThemeProvider>
        );
    }
}

const MainWrapper = styled(Box)(({ theme }) => ({
    '& .pdf-viewer': {
        position: 'relative',
        margin: '0 auto',
        width: '100%',
        height: '670px',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'scroll'
    },
    '& .pdf-controls': {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        background: '#F8FAFC',
        boxShadow: '0px 8px 32px 0px #0000000F',
        padding: '0px 20px',
        textAlign: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        height: '45px'
    },

    '& .canvas-container:': {
        height: '670px',
        overflow: 'auto',
        width: '100%',
    },
    '& .toolBar': {
        color: '#0F172A',
        fontSize: '16px',
        fontWeight: 400,
    },
    '& .activePage': {
        display: 'flex',
        justifyContent: 'center',
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        padding: '3px'
    },
    '& .buttons': {
        border: 'none',
        background: 'none',
        marginLeft: '4px',
        cursor: 'pointer'
    },
    "& .root": {
        display: 'flex',
        height: '100vh',
    },
    "& .sidebar": {
        borderLeft: '1px solid #ccc',
        padding: theme.spacing(2),
        backgroundColor: '#f4f4f4',
    },
    "& .toolbar": {
        display: 'flex',
        alignItems: 'center'
    },
    "& .canvas": {
        flex: 1,
        position: 'relative',
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    "& .pdfCanvas": {
        height: '90%',
        border: '1px solid #ccc',
    },
    '& .container': {
        backgroundColor: '#FFFFFF',
        top: 124,
        left: 1224,
        gap: 16,
        border: '0px solid transparent',
        borderLeft: '1px solid #E2E8F0',
    },
    '& .sectionTitle': {
        color: '#1E293B',
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '22px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        marginBottom: '8px',
    },
    '& .toolTypo': {
        color: '#475569',
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',

    },
    '& .lineTypo': {
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    },
    '& .label': {
        marginBottom: '8px',
        color: '#151818',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',

    },
    '& .inputGroup': {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '4px',
    },
    '& .input': {
        // width: '45%',
    },
    '& .area': {
        fontWeight: 600,
        color: '#007BFF',
        textDecoration: 'underline',
    },
    '& .lengthAndAreaTypo': {
        color: '#1E293B',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '22px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',

    },
    '& .lengthAndAreaDataTypo': {
        color: '#237182',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '22px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',

    },
    '& .startAdornmentTypo': {
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',

    },
}));
const CloseButton = styled(Button)({
    backgroundColor: "#237182",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: '24px',
    letterSpacing: 0,
    textTransform: "none",
    padding: "6px 10px 6px 10px",
    boxShadow: "none",
    fontFamily: "Poppins",
    borderWidth: 0,
    cursor:'pointer',
    '&:hover': {
        backgroundColor: '#237182',
    },
});
const CustomTextField = styled(TextField)({
    marginBottom: '8px',
    "& .MuiOutlinedInput-root": {
        height: '36px',
        borderRadius: '8px',
        borderColor: '#CBD5E1',
        backgroundColor: '#FFFFFF',
        "& fieldset": {
            borderColor: "#CBD5E1",
        },
        "&:hover fieldset": {
            border: "0.5px solid #CBD5E1",
        },
        "&.Mui-focused fieldset": {
            border: "0.5px solid #CBD5E1",
        },
    },
    "& .MuiOutlinedInput-input": {
        padding: '10px 8px',
        color: '#0F172A',
        fontSize: '14px',
        fontFamily: 'Poppins',
        lineHeight: '22px',
        "&::placeholder": {
            color: '#94A3B8',
            opacity: 1,
        },
    },
    '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: '8px',
        fontWeight: '400',
        lineHeight: '22',
        fontSize: '14px',
        color: '#475569',
    },
});
const CustomTextField2 = styled(TextField)({
    marginBottom: '8px',
    "& .MuiOutlinedInput-root": {
        height: '36px',
        borderRadius: '8px',
        borderColor: '#CBD5E1',
        backgroundColor: '#FFFFFF',
        "& fieldset": {
            borderColor: "#CBD5E1",
        },
        "&:hover fieldset": {
            border: "0.5px solid #CBD5E1",
        },
        "&.Mui-focused fieldset": {
            border: "0.5px solid #CBD5E1",
        },
    },
    "& .MuiOutlinedInput-input": {
        padding: '10px 0px',
        color: '#475569',
        fontSize: '14px',
        fontFamily: 'Poppins',
        fontWeight: '400PX',
        lineHeight: '22px',
        "&::placeholder": {
            color: '#94A3B8',
            opacity: 1,
        },
    },
    '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: '8px',
        fontWeight: '400',
        lineHeight: '22',
        fontSize: '14px',
        color: '#0F172A',
    },
});

const CustomInputLabel1 = styled(InputLabel)({
    marginBottom: '8px',
    color: '#475569',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
});
const CustomInputLabel2 = styled(InputLabel)({
    marginBottom: '8px',
    color: '#151818',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
});
const CustomUploadDialog = styled(Dialog)({
    "& .dialogTitleTypo": {
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
        letterSpacing: '-0.005em',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    },
    "& .subTitle":{
        color:'#1E293B',
        fontSize:'16px',
        fontWeight:400
    },
    "& .fileNameTypo": {
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    },
    "& .fileSizeTypo": {
        color: '#64748B',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    },
    "& .attachedFileTypo": {
        marginTop: '16px',
        color: '#334155',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    }


});
// Customizable Area End
