// Customisable Area Start
import React from "react";
import SpecialistCostPlanProjectsController, {
    Props,
} from "./SpecialistCostPlanProjectsController";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import {
    AppBar,
    Box,
    Button,
    createTheme,
    Dialog,
    DialogActions,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    styled,
    Tab,
    Tabs,
    TextField,
    ThemeProvider,
    Toolbar,
    Typography,
} from "@material-ui/core";
import { Clear, ControlPoint, ControlPointDuplicate, DateRange, DeleteOutline, KeyboardArrowDown, PersonOutlineOutlined, PlaceOutlined, Search } from "@material-ui/icons";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        body1: {
            fontFamily: "Poppins",
        },
    },
});

const StyledBox = styled(Box)({
    padding: "2rem 2.5rem",
    '@media screen and (max-width: 500px)': {
        padding: '2rem 2rem 2rem 1rem'
    },
    "& .pageTitle": {
        display: "flex",
        justifyContent: "space-between",
        height: '38px',
        marginBottom: "3.5rem",
    },
    "& .backButton": {
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
        cursor: "pointer",
        width: '353px',
        fontWeight: "600",
        "& p": {
            fontSize: "0.875rem",
            color: "#334155",
            fontWeight: 600,
        },
        "& .MuiSvgIcon-root": {
            fontSize: "1rem",
            color: "#334155",
        },
    },
    "& .titleText": {
        color: "#0F172A",
        fontSize: "1.5rem",
        fontWeight: 700,
        lineHeight: "2rem",
    },
    "& .buttonsBlock": {
        display: "flex",
        alignItems: "center",
        gap: "1rem",
    },
    "& .addMemberBtn": {
        display: "flex",
        gap: "0.5rem",
        cursor: "pointer",
    },
    "& .addMemberIcon": {
        width: "20px",
        height: "20px",
        color: "#237182",
    },
    "& .addMemberText": {
        fontWeight: 600,
        color: "#237182",
    },
    "& .saveDraftBtn": {
        padding: "10px 16px",
        borderRadius: "8px",
        background: "#E0EDF0",
        color: "#325962",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "1.5rem",
        textTransform: "none",
        fontFamily: 'Poppins',
    },
    "& .subTitle": {
        fontSize: "1.125rem",
        color: "#475569",
    },
    "& .titleBox": {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
    },
    "& .MuiGrid-spacing-xs-2": {
        margin: 0,
    },
    "& .planItem": {
        padding: "2rem 1.5rem",
        background: "#FFF",
        border: "1px solid #CBD5E1",
        borderRadius: "12px",
        height: "calc(100% - 4rem)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: "pointer",
        "& .planTitle": {
            fontSize: "1.25rem",
            fontWeight: 700,
            lineHeight: "1.75rem",
            color: "#1E293B",
            margin: "1.5rem 0 1rem",
        },
        "& .planDesc": {
            color: "#475569",
            marginBottom: "1rem",
        },
    },
    "& .selectedScopeItem": {
        border: "1px solid #237182",
    },
    "& .selectScopeBtn": {
        width: "100%",
        background: "#237182",
        padding: "10px 16px",
        borderRadius: "8px",
        color: "#FFF",
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: "1rem",
        textTransform: "none",
        "&:disabled": {
            background: "none",
            color: "#64748B",
        },
    },
});

const CreateButton = styled(Button)({
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    background: '#237182',
    borderRadius: '8px',
    padding: '10px 16px 10px 16px',
    textTransform: 'none' as 'none',
    cursor: 'pointer',
    marginLeft: '16px',
    '@media screen and (max-width: 1247px)': {
        padding: '5px'
    },
    '@media screen and (max-width: 1170px)': {
        fontSize: '14px',
        padding: '5px'
    },
    '@media screen and (max-width: 1155px)': {
        padding: '4px'
    }
})

const CustomTabs = styled((AppBar))({
    width: "100%",
    backgroundColor: "#F0F5F7",
    flexDirection: 'unset',
    "& .MuiTab-root": {
        minHeight: 'auto'
    },
    "& .MuiTabs-root": {
        minHeight: 'auto'
    },
})
const MenuDropdown = styled(Menu)({
    '& .MuiList-padding': {
        paddingTop: '3px',
        paddingBottom: '3px'
    },
    '& .MuiPaper-elevation8': {
        boxShadow: 'unset',
        border: '1px solid #E0EDF0'
    }
})
const CustomSearchBox = styled((TextField))({
    width: '100%',
    "& .MuiOutlinedInput-root": {
        borderRadius: '8px',
        height: '44px',
        [theme.breakpoints.down('md')]: {
            width: '200px'
        },
        [theme.breakpoints.down(1450)]: {
            width: '300px'
        },
        [theme.breakpoints.up(1450)]: {
            width: '538px'
        },
        "& fieldset": {
            borderColor: '#CBD5E1',
        },
        "&:hover fieldset": {
            borderColor: '#CBD5E1',
        },
        "&.Mui-focused fieldset": {
            borderColor: '#CBD5E1',
        },
    },
    "& .MuiOutlinedInput-input": {
        padding: "10px 0px",
        fontWeight: 400,
        fontSize: '16px',
        fontFamily: 'Poppins',
        "&::placeholder": {
            color: '#94A3B8',
            opacity: 1,
        }
    },
    "& .MuiInputLabel-outlined": {
        transform: "translate(10px, 10px)"
    },
    "& .MuiFormLabel-root": {
        fontSize: '15px',
        color: '#94A3B8'
    }
});
const ProjectCard = styled(Box)({
    background: '#fff',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: '0px 2px 8px 0px #00000014',
    position: 'relative',
});


const webStyle = {
    displayGap: {
        display: 'flex',
        gap: '4px',
        marginTop: '8px'
    },
    projectTitle: {
        color: '#0F172A',
        fontSize: '16px',
        fontWeight: 600,
        fontFamliy: 'Poppins',
        textAlign: 'left' as 'left'
    },
    subText: {
        color: '#64748B',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400
    },
    moreIcon: {
        color: '#475569',
        position: 'absolute' as 'absolute',
        top: 20,
        right: 5
    },
    icons: {
        color: '#64748B',
        width: '20px',
        height: '20px'
    },
    downloadText: {
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        marginLeft: '8px'
    },
}
const styleSheet =
{
    logoutButton: {
        background: "#237182", color: "#FFFFFF", fontSize: "16px", fontWeight: 600,
        fontFamily: "Poppins", textTransform: "none", height: "56px", borderRadius: "8px", width: "180px",
        padding: "10px 16px 10px 16px",
    } as const,
    logoutButton2:
        {
            background: "#237182", color: "#FFFFFF", fontSize: "16px", fontFamily: "Poppins",
            textTransform: "none", height: "56px", borderRadius: "8px", width: "121px",
            padding: "10px 16px 10px 16px", fontWeight: 600,
        } as const,
    cancelButton:
        {
            backgroundColor: "#E2E8F0", color: "#325962", fontSize: "16px", fontFamily: "Poppins",
            textTransform: "none", height: "56px", borderRadius: "8px", width: "96px", fontWeight: 600,
        } as const,
    title:
    {
        fontWeight: 700, fontSize: "24px", color: "#0F172A", fontFamily: "Poppins",
        lineHeight: "32px", letterSpacing: "-0.005em"
    },
    cursorStyle: { cursor: 'pointer' }
}
export default class SpecialistCostPlanProjects extends SpecialistCostPlanProjectsController {
    constructor(props: Props) {
        super(props);

    }
    getBorderColor = (activeTab: number) => {
        switch (activeTab) {
            case 0:
                return '#F59E0B';
            case 1:
                return '#10B981';
            case 2:
                return '#EF4444';
            case 3:
                return '#475569';
            default:
                return '#F59E0B';
        }
    };
    getTabStyle = (isActiveTab: boolean, index: number): React.CSSProperties => {
        const borderRadius = index === 4 ? "8px" : "25px";
        return {
            minWidth: "auto",
            paddingInline: "20px",
            textTransform: "none",
            borderRadius,
            backgroundColor: isActiveTab ? "#E0EDF0" : "#F8FAFC",
            color: isActiveTab ? "#0F172A" : "#64748B",
            fontWeight: isActiveTab ? "bold" : 600,
            fontFamily: "Poppins",
        };
    };

    render() {
        const projects = [
            { projectId: 12, projectTitle: 'Grand Summit Estates: A Magnificent Residential Development', date: 'Submitted: 4 Dec 2024', location: 'Rio de Janeiro, Brazil', submittedBy: 'Alexander Christopher' },
            { projectId: 13, projectTitle: 'Grand Summit Estates: A Magnificent Residential Development', date: 'Submitted: 4 Dec 2024', location: 'Rio de Janeiro, Brazil', submittedBy: 'Alexander Christopher' },
            { projectId: 23, projectTitle: 'Grand Summit Estates: A Magnificent Residential Development', date: 'Submitted: 4 Dec 2024', location: 'Rio de Janeiro, Brazil', submittedBy: 'Alexander Christopher' },
            { projectId: 14, projectTitle: 'Grand Summit Estates: A Magnificent Residential Development', date: 'Submitted: 4 Dec 2024', location: 'Rio de Janeiro, Brazil', submittedBy: 'Alexander Christopher' },
            { projectId: 16, projectTitle: 'Grand Summit Estates: A Magnificent Residential Development', date: 'Submitted: 4 Dec 2024', location: 'Rio de Janeiro, Brazil', submittedBy: 'Alexander Christopher' },
            { projectId: 18, projectTitle: 'Grand Summit Estates: A Magnificent Residential Development', date: 'Submitted: 4 Dec 2024', location: 'Rio de Janeiro, Brazil', submittedBy: 'Alexander Christopher' },
        ]
        const tabs = [
            { label: "Pending", index: 0 },
            { label: "Approved", index: 1 },
            { label: "Rejected", index: 2 },
            { label: "Draft", index: 3 },
        ];
        const renderTab = ({ label, index }: { label: string; index: number }) => {
            const isActiveTab = this.state.activeTab === index;
            const tabStyle = this.getTabStyle(isActiveTab, index);

            return <Tab key={label} label={label} style={tabStyle} />;
        };
        return (
            <ThemeProvider theme={theme}>
                <MyProfileHeader navigation={this.props.navigation} tabName="Specialist">
                    <StyledBox>
                        {this.state.openDialogLeave && <Dialog PaperProps={{
                            style: {
                                width: '584px',
                                height: '245px',
                                borderRadius: "16px",
                                padding: "40px 32px 40px 32px",
                                color: "#FFFFFF"
                            }
                        }} open={this.state.openDialogLeave} onClose={this.handleCloseDialog}>
                            <Box >
                                <Box style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}><Typography style={styleSheet.title} >Are you sure you want to duplicate this project?</Typography> <Typography onClick={this.handleCloseDialog} style={{
                                    color: "black",
                                    width: "20px",
                                    marginRight: "15px",
                                    marginTop: "5px"
                                }}><Clear /></Typography>

                                </Box>
                                <Box sx={{ marginTop: '16px', paddingRight: '50px' }}>
                                    <Typography style={{
                                        color: '#1E293B',
                                        fontFamily: 'Poppins',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                    }} >All tasks within this project will be duplicated, including their details and associated files.</Typography>
                                </Box>
                                <DialogActions style={{
                                    marginTop: "45px",
                                    gap: "10px"
                                }}>
                                    <Button style={styleSheet.cancelButton} onClick={this.handleCloseDialog}>
                                        Cancel
                                    </Button>
                                    <Button data-test-id="handleDuplicateProject" style={styleSheet.logoutButton} onClick={this.state.handleDuplicateProject ? this.state.handleDuplicateProject : undefined}>
                                        Duplicate Project
                                    </Button></DialogActions>

                            </Box>
                        </Dialog>}
                        <CustomTabs position="static" elevation={0}>
                            <Toolbar
                                style={{
                                    paddingLeft: "8px",
                                    paddingRight: "8px",
                                    width: "100%",
                                    backgroundColor: "#ffffff",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignSelf: "center",
                                    marginTop: "24px",
                                    borderRadius: "8px",
                                }}
                            >
                                <Tabs
                                    value={this.state.activeTab}
                                    data-test-id="handleTabChange"
                                    onChange={this.handleTabChange}
                                    TabIndicatorProps={{
                                        style: {
                                            display: "none",
                                        },
                                    }}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    style={{ backgroundColor: "#F8FAFC", borderRadius: "18px", marginRight: "10px" }}
                                >
                                    {tabs.map(renderTab)}
                                </Tabs>

                                <Divider orientation="vertical" flexItem style={{ margin: "auto 5px", height: "40px" }} />
                                <Box flexGrow={1} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ color: "#334155", fontSize: '14px', fontWeight: 400, paddingInline: '4px', fontFamily: 'Poppins' }}>
                                        Sort By:
                                    </Typography>
                                    <Typography style={{
                                        color: "#237182",
                                        fontSize: "16px",
                                        fontWeight: 600,
                                    }}
                                        data-test-id="openSortDropdown"
                                        onClick={(event: any) => this.openSortDropdown(event)}>{this.state.sortTypeText}</Typography>
                                    <KeyboardArrowDown style={{ color: '#237182' }} data-test-id="openSortDropdown" onClick={(event: any) => this.openSortDropdown(event)} />

                                    <MenuDropdown
                                        anchorEl={this.state.menuAnchorSort}
                                        open={Boolean(this.state.menuAnchorSort)}
                                        data-test-id='handleMenuClose'
                                        onClose={this.handleMenuClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        PaperProps={{
                                            style: {
                                                marginTop: "46px",
                                                padding: '1px 4px',
                                                borderRadius: '8px',
                                                width: '184px'
                                            },
                                        }}
                                    >
                                        {this.state.sortType.map((option: { value: string; label: string }) => (
                                            <MenuItem data-test-id="handleSortByChange" onClick={() => this.handleSortByChange(option.value, option.label)}
                                                key={option.value} value={option.value} style={{ ...webStyle.downloadText, marginLeft: 'unset' }}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </MenuDropdown>
                                </Box>

                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                    <CustomSearchBox
                                        data-test-id="searchInputText"
                                        variant="outlined"
                                        placeholder="Search here..."
                                        value={this.state.searchText}
                                        onChange={(e: any) => this.handleSearchChange(e)}
                                        error={!!(this.getFilteredAndSortedProjects().length === 0 && !this.state.loading && this.state.searchText)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search style={{ color: '#94A3B8' }} />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                this.state.searchText && (
                                                    <InputAdornment style={{ marginRight: '-10px' }} position="end">
                                                        <IconButton onClick={() => this.setState({ searchText: '' })}>
                                                            <Clear />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            ),
                                        }}
                                    />
                                    {this.getFilteredAndSortedProjects().length === 0 && !this.state.loading && this.state.searchText && <Box style={{ backgroundColor: '#FCE8E5', borderRadius: '24px', padding: '5px', height: '20px', position: 'absolute', marginTop: '50px', color: '#DC2626', fontFamily: 'Poppins', fontWeight: '600px', fontSize: '12px' }}>No project exists with this name</Box>}
                                </Box>
                                <CreateButton data-test-id="handleCreateNew" onClick={this.handleCreateNew}>
                                    <ControlPoint />
                                    Create Project
                                </CreateButton>
                            </Toolbar>
                        </CustomTabs>

                        <Box>
                            <Grid container spacing={2} style={{ marginTop: '24px' }}>
                                {projects.map((item, index) => (
                                    <Grid item xs={12} sm={6} lg={3} md={3}>
                                        <ProjectCard style={{ borderTop: `4px solid ${this.getBorderColor(this.state.activeTab)}` }}>
                                            <Box data-test-id="handleMenuOpen"
                                                style={{ ...webStyle.moreIcon, cursor: "pointer", background: this.state.menuItemIndex === index && this.state.menuAnchor ? '#E0EDF0' : 'none', borderRadius: '50%', padding: '4px', height: '25px' }}
                                                onClick={(event: any) => this.handleMenuOpen(event, index)}>

                                                <MoreVertIcon />
                                            </Box>
                                            <MenuDropdown
                                                anchorEl={this.state.menuAnchor}
                                                open={Boolean(this.state.menuAnchor)}
                                                data-test-id='handleMenuClose'
                                                onClose={this.handleMenuClose}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "right",
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                }}
                                                PaperProps={{
                                                    style: {
                                                        marginTop: "46px",
                                                        padding: '1px 4px',
                                                        borderRadius: '8px',
                                                        width: '184px'
                                                    },
                                                }}
                                            >
                                                <MenuItem style={{ background: '#E0EDF0', borderRadius: '8px', cursor: 'pointer' }}
                                                    data-test-id="openDuplicateDialog"
                                                    onClick={() => this.openDuplicateDialog(item.projectId)}
                                                >
                                                    <ControlPointDuplicate />
                                                    <Typography style={webStyle.downloadText}>Duplicate</Typography>
                                                </MenuItem>
                                                <MenuItem style={{ cursor: 'pointer' }}
                                                >
                                                    <DeleteOutline style={{ color: '#DC2626' }} />
                                                    <Typography style={{ ...webStyle.downloadText, color: '#DC2626' }}>Delete</Typography>
                                                </MenuItem>
                                            </MenuDropdown>
                                            <Box data-test-id="projectClick" onClick={() => this.projectClick(item.projectId)}>
                                            <Typography style={webStyle.projectTitle}>
                                                {item.projectTitle}
                                            </Typography>
                                            <Box style={webStyle.displayGap}>
                                                <DateRange style={webStyle.icons} />
                                                <Typography style={webStyle.subText}>{item.date}</Typography>
                                            </Box>
                                            <Box style={webStyle.displayGap}>
                                                <PlaceOutlined style={webStyle.icons} />
                                                <Typography style={webStyle.subText}>{item.location}</Typography>
                                            </Box>
                                            <Box style={webStyle.displayGap}>
                                                <PersonOutlineOutlined style={webStyle.icons} />
                                                <Typography style={webStyle.subText}>Submitted by: <span>{item.submittedBy}</span></Typography>
                                            </Box>
                                            </Box>
                                        </ProjectCard>

                                    </Grid>
                                ))}

                            </Grid>
                        </Box>
                    </StyledBox>
                </MyProfileHeader>
            </ThemeProvider>
        );
    }
}
// Customisable Area End
