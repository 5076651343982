// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import * as React from "react";


const { PDFDocumentProxy } = require("pdfjs-dist");
const pdfjsLib = require("pdfjs-dist");
if (!pdfjsLib.GlobalWorkerOptions.workerSrc) {
  const pdfjsVersion = require('pdfjs-dist/package.json').version;
  pdfjsLib.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`;
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  token: string;
  uploadedFile: any;
  uploadFileUrl: string;
  fileName: string;
  numPages: number;
  pageNumber: number;
  scale: number;
  zoomLevel: number;
  tool: "line" | "rectangle" | "arrow" | "hand" | null;
  lines: {
    id: string;
    start: { x: number; y: number };
    end: { x: number; y: number };
    length: number;
  }[];
  rectangles: {
    id: string;
    start: { x: number; y: number };
    end: { x: number; y: number };
    area: number;
    width: number;
    height: number;
  }[];
  dragging: boolean;
  startPoint: { x: number; y: number } | null;
  pixels: number;
  realWorldDistance: number;
  lineWidth: number;
  lineColor: string;
  rectangleBorderColor: string;
  rectangleBackgroundColor: string;
  isMounted: boolean;

  unit: string;
  endpoint1: { x: number; y: number };
  endpoint2: { x: number; y: number };
  length: number;
  isDrawing: boolean;
  shapes: any[],
  currentShape: any,
  searchText: string;
  toolTypeSelected: string;
  selectedToolImage: any,
  currentPage: number;
  totalPages: number;
  zoom: number;
  openDialog: boolean;
  selectedImage: any,
  canvasContext: any,
  pdfDoc: any,
  isDragging: boolean;
  dragStart: any;
  viewportOffset: any
}

interface SS {
  id: any;
}

export default class MarkupToolController extends BlockComponent<Props, S, SS> {
  canvasContainerRef: React.RefObject<HTMLDivElement>;
  canvasRef: React.RefObject<HTMLCanvasElement>;

  pageRendering: boolean = false;
  pageNum: number = 1;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      token: "",
      uploadedFile: "",
      uploadFileUrl: "",
      fileName: "",
      numPages: 0,
      pageNumber: 1,
      scale: 1,
      zoomLevel: 100,
      tool: null,
      lines: [],
      rectangles: [],
      dragging: false,
      startPoint: null,
      pixels: 10,
      realWorldDistance: 100,
      lineWidth: 4,
      lineColor: "#34D399",
      rectangleBorderColor: "#34D399",
      rectangleBackgroundColor: "#34D3991F",
      isMounted: false,
      isDrawing: false,
      searchText: '',
      unit: 'Meters(m)',
      endpoint1: { x: 700, y: 600 },
      endpoint2: { x: 0, y: 0 },
      length: 100,
      shapes: [],
      currentShape: null,
      toolTypeSelected: 'Electrical Tools',
      selectedToolImage: null,
      currentPage: 1,
      totalPages: 0,
      zoom: 1,
      openDialog: false,
      selectedImage: null,
      canvasContext: null,
      pdfDoc: null,
      isDragging: false,
      dragStart: { x: 0, y: 0 },
      viewportOffset: { x: 0, y: 0 },
    };

    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);

    this.canvasRef = React.createRef();
    this.canvasContainerRef = React.createRef();
  }
  async componentDidMount() {
    const token = await getStorageData("authToken");
    this.setState({ token });
    await this.getFileFromStorage();

    await this.loadPDF()
    window.addEventListener('keydown', this.handleKeyDown);
    const canvas = this.canvasRef.current;
    if (canvas) {
      canvas.addEventListener('click', this.handleCanvasClick);
      canvas.addEventListener('mousedown', this.handleCanvasMouseDown);
      canvas.addEventListener('mousemove', this.handleCanvasMouseMove);
      canvas.addEventListener('mouseup', this.handleCanvasMouseUp);
    }
  }
  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowRight') {
      this.handleNextPage();
    } else if (event.key === 'ArrowLeft') {
      this.handlePreviousPage();
    }
  };
  async componentWillUnmount(): Promise<void> {
    if (this.state.uploadedFile) {
      URL.revokeObjectURL(this.state.uploadedFile);
    }
    this.setState({ isMounted: false });

    window.removeEventListener('keydown', this.handleKeyDown);
    const canvas = this.canvasRef.current;
    if (canvas) {
      canvas.removeEventListener('click', this.handleCanvasClick);
      canvas.removeEventListener('mousedown', this.handleCanvasMouseDown);
      canvas.removeEventListener('mousemove', this.handleCanvasMouseMove);
      canvas.removeEventListener('mouseup', this.handleCanvasMouseUp);
    }

  }
  handleToolClick = (image: string) => {
    this.setState({ selectedImage: image });
  };

  handleCanvasClick = (event: any) => {
    const { selectedImage } = this.state;

    if (!selectedImage || !this.canvasRef.current) {
      return;
    }

    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');

    if (!context) {
      return;
    }

    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const img = new Image();
    img.src = selectedImage;
    img.onload = () => {
      context.drawImage(img, x - img.width / 2, y - img.height / 2);
    };
  };

  async getFileFromStorage() {
    try {
      const fileObjString = await getStorageData("fileObj");
      if (!fileObjString) return;

      const fileObj = JSON.parse(fileObjString);
      this.setState(
        {
          uploadFileUrl: fileObj.fileUrl,
          fileName: fileObj.fileMetaData.name,
        },
      );
    } catch (error) {
      console.error("Error retrieving or parsing data from localStorage:", error);
    }
  }
  loadPDF = async () => {
    const url = this.state.uploadFileUrl;
    try {
      const pdf = await pdfjsLib.getDocument(url).promise;
      this.setState({ pdfDoc: pdf })
      this.setState({ totalPages: pdf.numPages }, () => {
        this.renderPage(this.state.pageNumber);
      });
    } catch (error) {
      console.error('Error loading PDF:', error);
    }
  };

  renderPage = (num: number) => {
    if (this.pageRendering) return;
    this.pageRendering = true;

    const { zoom, viewportOffset } = this.state;

    this.state.pdfDoc.getPage(num).then((page: any) => {
      const canvas = this.canvasRef.current;
      if (!canvas) return;
      const context = canvas.getContext('2d');
      const viewport = page.getViewport({ scale: zoom });

      canvas.width = viewport.width;
      canvas.height = viewport.height;
      
      page.render({
        canvasContext: context!,
        viewport: viewport,
      }).promise.then(() => {
        context?.restore();
        this.pageRendering = false; this.setState({ currentPage: num });
      });
    });
  };


  handleNextPage = () => {
    if (this.state.pageNumber < this.state.totalPages) {
      this.setState({ pageNumber: this.state.pageNumber + 1 });
      this.renderPage(this.state.pageNumber);
    }
  };

  handlePreviousPage = () => {
    if (this.state.pageNumber > 1) {
      this.setState({ pageNumber: this.state.pageNumber - 1 })
      this.renderPage(this.state.pageNumber);
    }
  };

  handleZoomIn = () => {
    this.setState(
      (prevState) => ({ zoom: prevState.zoom + 0.1 }),
      () => this.renderPage(this.state.pageNumber)
    );
  };

  handleZoomOut = () => {
    this.setState(
      (prevState) => ({ zoom: prevState.zoom - 0.1 }),
      () => this.renderPage(this.state.pageNumber)
    );
  };


  renderingInProgress = false;

  handleSave = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "DrawingDashboard"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);

  }
  selectTool = (tool: 'arrow' | 'hand') => {
    this.setState({ tool });
  };
  handleGoBack = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "DrawingDashboard"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value });
  };
  handleClose = () => {
    this.setState({ openDialog: true })
  }
  handleCLoseDialog = () => {
    this.setState({ openDialog: false })
  }

  handleCanvasMouseDown = (event: MouseEvent) => {
    const { tool } = this.state;
    if (tool == 'hand') {
      this.setState({
        isDragging: true,
        dragStart: { x: event.clientX, y: event.clientY },
      });
    }
  };

  handleCanvasMouseMove = (event: MouseEvent) => {
    const { tool, isDragging, dragStart, viewportOffset } = this.state;
    if (tool == 'hand' && isDragging) {
      const dx = event.clientX - dragStart.x;
      const dy = event.clientY - dragStart.y;

      this.setState({
        dragStart: { x: event.clientX, y: event.clientY },
        viewportOffset: {
          x: viewportOffset.x + dx,
          y: viewportOffset.y + dy,
        },
      });

    }
  };

  handleCanvasMouseUp = () => {
    this.setState({ isDragging: false });
  };


  onMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (this.state.tool == 'hand' && this.state.isDragging) {
      const { dragStart, viewportOffset } = this.state;
      const deltaX = event.clientX - dragStart.x;
      const deltaY = event.clientY - dragStart.y;

      this.setState({
        dragStart: { x: event.clientX, y: event.clientY },
        viewportOffset: {
          x: viewportOffset.x + deltaX,
          y: viewportOffset.y + deltaY,
        },
      }, () => {
        this.renderPage(this.state.currentPage);
      });
    }
  };

  onMouseUp = () => {
    if (this.state.isDragging) {
      this.setState({ isDragging: false });
    }
  };
  onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (this.state.tool == 'hand') {
      event.preventDefault();
      this.setState({ isDragging: true, dragStart: { x: event.clientX, y: event.clientY } });
    }
  };



}
// Customizable Area End